import {
    SET_USER_STATUS_FILTER,
    SET_SOCIALISE_FILTER,
    SET_LOCAL_HUB_FILTER,
    CLEAR_SOCIALISE_FILTER,
    CLEAR_LOCAL_HUB_FILTER
} from "../types";

// login user action creator
export const setFilterToActiveUsers = (payload) => (dispatch) => {
    dispatch({ type: SET_USER_STATUS_FILTER, payload:payload });
};

export const setSocialiseFilter = (payload) => (dispatch) => {
    dispatch({ type: SET_SOCIALISE_FILTER, action: payload });
};

export const setLocalHubFilter = (payload) => (dispatch) => {
    dispatch({ type: SET_LOCAL_HUB_FILTER, action: payload });
};

export const clearSocialiseFilter = () => (dispatch) => {
    dispatch({ type: CLEAR_SOCIALISE_FILTER });
};

export const clearLocalhubFilter = () => (dispatch) => {
    dispatch({ type: CLEAR_LOCAL_HUB_FILTER });
};