import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPageData, setPageStats} from "../redux/actions/pageAction";
import { getUserData } from "../redux/actions/userActions";
import SpringModal from "../components/layout/sections/Modal";
import { openModal } from "../redux/actions/pageAction";
import { fontSize } from "@mui/system";
import { Link } from 'react-router-dom';

class UserProfile extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setPageStats(true);
        this.props.setPageData('user-profile');
        this.props.getUserData(window.location.href.split('/')[4]);
    }

    render(){
        return(
        <div className="userProfile_container">
            <SpringModal />
            { this.props.userData ? 
            <div className="userProfileMainSection">
                
                { this.props.userData?.userProfileImage ? 
                    <div className="userProfileImageSection">
                        <input type="image" src={`/users/${this.props.userData?.userProfileImage}`} alt="user images" className="singleUserProfileImage" />
                        <input type="image" src="/editProfileImage.svg" alt="edit user image" onClick={ this.props.openModal } className="editUserImageButton" />
                    </div>
                : null }
                <div className="mainUserProfileDetailsSection">
                {
                    this.props.userData?.username ?
                        <label>{ this.props.userData.username }</label>
                    : null
                }
                    <div className="userAge">
                        { this.props.userData.userProfile?.age } y.o.
                    </div> 
                    <div className="statusLanguage">
                        { this.props.userData.userProfile?.relationshipStatus }
                    </div>
                    <div className="profileDetailsSection">
                        <div className="profilePointsSection">
                            <input type="image" src="/medal.svg" alt="points profile" className="pointsImage" />
                            <div>
                                {/* {this.props.userData?.userDetails.points} */}
                                { Math.floor(Math.random() * 50) }
                            </div>
                            <div>
                                points
                            </div>
                        </div>
                        <div className="profileLocationSection">
                        <input type="image" src="/locationPointer.svg" alt="points profile" className="locationImage" />
                            <div>
                                {/* {this.props.userData?.userDetails.distance} */}
                                { Math.floor(Math.random() * 100) }
                            </div>
                            <div>
                                km
                            </div>
                        </div>
                        <div className="profileReviewSection">
                        <input type="image" src="/reviewIcon.svg" alt="points profile" className="reviewImage" />
                            <div>
                                {/* {this.props.userData?.userDetails.reviews} */}
                                { Math.floor(Math.random() * 40) }
                            </div>
                            <div>
                                review
                            </div>
                        </div>
                    </div>
                </div>

                <div className="profileDescriptionTextareaSection">
                    <div className="profileDescriptionTextArea">
                        This is description text and text about the user
                    </div>
                </div> 

                { this.props.userData?.linkedAccounts ? 
                <div className="profileLinkedAccountSection">
                    <label>Linked Accounts:</label>
                    <div className="linkedAccountImageBlock">
                        {
                            this.props.userData?.linkedAccounts.map((user, index) =>
                                <Link key={user.id} to={`/user-profile/${user.id}`} >
                                    <div className="singleLinkedAccountImageBlock">
                                        <input type="image" src={`/users/${user.image}`} className="singleImage" alt="linked account images and links"/>
                                        { user.onlineStatus ? 
                                            <input type="image" src="/active_dot.png" className="sungleImageActiveUsers" alt="Single image active users" />
                                        : null }
                                    </div>
                                </Link>
                            )
                        }
                    </div>
                </div>
                : <div></div> }

                { this.props.userData?.userReviews ? 
                    <div className="profileReviewAccountSection">
                        <label>All Reviews:</label>
                        <div className="reviewAccountBlock">
                            {
                                this.props.userData?.userReviews.map((review, index) =>
                                    <div className="userBlockReview" key={index}>
                                        <div className="singleReviewImageBlockParent">
                                            <div className="singleReviewImageBlock">
                                                <input type="image" src={`/users/${review.image}`} className="singleImage" alt="linked account images and links"/>
                                                { review.onlineStatus ? 
                                                    <input type="image" src="/active_dot.png" className="singleImageActiveUsersReview" alt="Single image active users" />
                                                : null }
                                            </div>
                                        </div>
                                        <div className="singleReviewDescriptionBlock">
                                            { review.description }
                                            
                                            <div className="singleReviewDescriptionLinkBlock">
                                                <Link>Give a review back</Link>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )
                            }
                        </div>
                    </div>
                : <div></div> }
            
            </div>
            : null }


        </div>);
    }
}

UserProfile.propTypes = { userData: PropTypes.object.isRequired }
function mapStateToProps(state) {
    return {
        userData: state.users.userData
    }
}
export default connect(mapStateToProps, {setPageData, getUserData, openModal, setPageStats})(UserProfile);