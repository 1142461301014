import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {setPageData, setShoutOutsData, setPageStats} from "../redux/actions/pageAction";
import Posts from "../components/posts/posts";
import SpringModal from "../components/layout/sections/Modal";

class ShoutOuts extends Component {
    componentDidMount() {
        this.props.setPageStats(true);
        this.props.setPageData('shoutouts');
        this.props.setShoutOutsData('test');
    }
    render() {
        return (
            <div className="shotouts_container">
                <SpringModal />
                { this.props.posts.map((post) => (
                    <Posts key={post.id} post={post}/>
                )) }
            </div>
        );
    }
}
ShoutOuts.propTypes = {
    data: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        data: state.page.data,
        posts: state.page.shoutOuts
    }
}
export default connect(mapStateToProps, {setPageData, setShoutOutsData, setPageStats})(ShoutOuts);