import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";


class PageTitle extends Component {
    render(){
        const publication = this.props.data;
        let pageTitle =  publication[0].toUpperCase() + publication.substring(1);
        if(publication === 'shoutouts') 
        {
            pageTitle = "Shout Outs"
        } 
        else if(publication === 'localhub') 
        {
            pageTitle = "Local Hub"
        } 
        else if (publication === "user-profile") 
        {
            pageTitle = "User's Profile"
        }
        return(
            <div className="navBarTitle">
                { pageTitle }
            </div>
        )
    }
}

PageTitle.propTypes = {
    data: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        data: state.page.data
    }
}
export default connect(mapStateToProps)(PageTitle);