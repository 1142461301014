import { Component } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PageFilters from "../../pageFilters";


class RightSection extends Component {
    constructor(props) { super(props) };
    componentDidMount() {}
    render = () => {
        return (
            <div>
            {   this.props.page === "user-profile" || 
                this.props.page === "outfits" || 
                this.props.page === "shoutouts" ?
                
                <div className="rightSectionBanner">
                    <div className="bannerContentSection">
                        <p>Socialising doesn’t need to be so hard.</p>
                        <p>Do something spontaneous today.</p>
                    </div>
                    <div className="bannerTitleSection">
                    Download now for free
                    </div>
                    <div className="bannerImagesSection">
                        <input type="image" src="/app_store_img.png" className="appleStoreBannerImage" alt="apple store banner image" /> 
                        <input type="image" src="/google_playstore.png" className="playStoreBannerImage" alt="play store banner image" /> 
                    </div>
                </div>
            : this.props.page === "socialise" ? 
            <div className="rightFilterBox">
                <PageFilters page="socialise" />
            </div>
            : this.props.page === "localhub" ? 
            <div className="rightFilterBox">
                <PageFilters page="localhub" />
            </div>
            : null
            }
            </div>
        );
    };
}   

RightSection.propTypes = {
    page: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        page: state.page.data
    }
}
export default connect(mapStateToProps)(RightSection);
