import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Wavesurfer from 'react-wavesurfer';
import { openModal } from "../../redux/actions/pageAction";
import Carousel from "react-gallery-carousel";
import 'react-gallery-carousel/dist/index.css';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

class Posts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            pos: 0
        };
        
        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        this.handlePosChange = this.handlePosChange.bind(this);
    }

    handleTogglePlay() {
        this.setState({
            playing: !this.state.playing
        });
    }

    handlePosChange(e) {
        this.setState({
            pos: e.originalArgs[0]
        });
    }

    toTime(seconds) {
        let date = new Date(null);
        date.setSeconds(seconds);
        let dateFormat = date.toISOString().substr(11, 8);
        return dateFormat.substr(3,8)
    }

    render() {
        const { post } = this.props;
        
        const postMedia = () => {
            let returnMedia = [];
            let returnType = 'image';
            let allowedImageTypes = ["jpg", "png"];
            let allowedVideoTypes = ["mp4"]; 
            if(Array.isArray(post.media))
            {
                // eslint-disable-next-line
                post.media.map((media) => {
                    // eslint-disable-next-line
                    allowedImageTypes.map((imageType) => {
                        if(media.endsWith(imageType)){
                            returnMedia.push({
                                src: media,
                                sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
                            });
                            returnType = "imageArray";
                        }
                    });                
                    // eslint-disable-next-line
                    allowedVideoTypes.map((imageType1) => {                
                        if(media.endsWith(imageType1)){
                            returnMedia.push(media);
                            returnType = "videoArray";
                        }
                    });
                });
            }
            else {
                // eslint-disable-next-line
                allowedImageTypes.map((imageType) => {
                    if(post.media.endsWith(imageType))
                    {
                        returnMedia.push(post.media)
                    }
                });
                // eslint-disable-next-line
                allowedVideoTypes.map((videoType) => {
                    if(post.media.endsWith(videoType))
                    {
                        returnMedia.push(post.media);
                        returnType = "video";
                    }
                })   
            }

            return {
                type: returnType,
                return: returnMedia
            };
        }


        return (
            <div className="postDetailsSection">
                <div className="postImageMedia">
                    {
                        postMedia().type === 'image' ? 
                            <input type="image" className="postImage" alt="post image" src={postMedia().return[0]} /> : 
                        postMedia().type === 'imageArray' ? 
                            <Carousel className="postImage framed-carousel" images={postMedia().return} style={{ width: '100%', height: 300 }}
                            isMaximized={false}
                            hasSizeButton={false}
                            hasMediaButton={false}
                            hasIndexBoard={false}
                            hasLeftButton={false}
                            hasRightButton={false}
                            hasCaptionsAtMax='top'
                            hasDotButtonsAtMax='bottom'
                            hasThumbnails={false}
                            hasThumbnailsAtMax={false}
                            thumbnailWidth={'15%'}
                            thumbnailHeight={'15%'}
                            shouldMaximizeOnClick={true}
                            shouldMinimizeOnClick={true}
                            isRTL={true}
                            /> : 
                        postMedia().type === 'video' ? 
                        <Player>
                            <source src={postMedia().return[0]} />
                        </Player>: null
                    }
                
                    { post.is_new ? <input type="image" className="newPostImage" src="newPostButton.svg" alt="new post image" /> : null }
                    
                    { post.actions?.like_dislike ? 
                    <div className="likeDislikeSection">
                        <input type="image" className="postLikeImage" src="postLike.svg" alt="post like image" onClick={ this.props.openModal } />
                        <input type="image" className="postDislikeImage" src="postDislike.svg" alt="post like image" onClick={ this.props.openModal } />
                    </div>
                    : null }

                </div>
                <div className="postTextSection">
                    <div className="userImageSection">
                        <div className="profileRing">
                            <input type="image" src={post.user.image} className="postProfileImage" alt="user post image" />
                            { post.user.is_premium ? <input type="image" style={{ width: 20, position: "absolute", top: 10, left: "-8px" }} src="medal.svg" alt="premium user on post" /> : null }
                        </div>
                    </div>
                    <div className="postDetailTextSection">
                        <div className="userNameContainer">
                            <div className="username">
                                <div className="username_text_section">
                                    { post.user.name }
                                    <img alt="userIcn" src="icon/user.svg" />
                                </div>
                                <div className="socialPostBtns">
                                    <img alt="post_like" src="icon/like_icon.svg" />
                                    <img alt="post_comment" src="icon/comment_icon.svg" />
                                    <img alt="post_share" src="icon/share_icon.svg" />
                                </div>
                            </div>
                        </div>
                        { post?.title ? <div className="postDetailContainer">
                            { post.title }
                        </div> : ''}

                        { post.audioFile ? <div className="waveSurferContainerClass">
                            <div className="mediaControlClass" onClick={ this.handleTogglePlay }>
                                <input type="image" style={{ width: 24 }} src={ this.state.playing ? "stopBtn.svg" : "playBtn.svg"} alt="control the sound" />
                            </div>
                            <div className="soundTimeLineClass">
                                <Wavesurfer
                                    audioFile={post.audioFile}
                                    pos={this.state.pos}
                                    onPosChange={this.handlePosChange}
                                    playing={ this.state.playing }
                                    options={{
                                        barWidth: 5,
                                        height: 43,
                                        progressColor: "white", 
                                        waveColor: "#ffffff5e",
                                        cursorColor: "transparent"
                                    }}
                                />
                            </div>
                            <div className="mediaTimeFrameClass">
                                { this.toTime(this.state.pos) }
                            </div>

                        </div> : '' }

                        { post.description ? <div className="postTextContainer">
                            { post.description }
                        </div> : '' }

                        <div className="commentTestContainer" onClick={ this.props.openModal }>
                            <input disabled type="text" placeholder="Your comment here" className="inputText" />
                            <img src="sendIcon.svg" alt="send Icon" className="sendImageBtn" />
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

Posts.propTypes = {
    data: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        data: state.page.data
    }
}
export default connect(mapStateToProps, { openModal })(Posts);