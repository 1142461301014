import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormGroup } from "@mui/material";
import { setSocialiseFilter, setLocalHubFilter } from "../redux/actions/filterSearchAction";
import Checkbox from "react-custom-checkbox";
import { Icon }  from "@mui/material";

import { SET_LOCAL_HUB_FILTER } from "../redux/types";
import { width } from "@mui/system";


class PageFilters extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const socializeFields = 
        [
            {
                label: '',
                fields: [ 'Solo','Couple','In a group','a Family' ]
            }, 
            {
                label: 'Age',
                fields: ['18+','25+','35+','45+','55+']
            }
        ];

        const localhubFields = ['Food','Hotels','Parmacy','Night Club','Drinks'];

        const handleChange = (event) => {
            const { socialise_filter, setSocialiseFilter, localhub_filter, setLocalHubFilter } = this.props;
            let filterLocal = [];
            if(event.target.id === 'socialise') 
            {
                console.log(this);
                
                // console.log(socialise_filter, event.target.value);
                // console.log(socialise_filter);

                // console.log([...socialise_filter, event.target.value]);
                setSocialiseFilter([socialise_filter, event.target.value]);

                // socialise_filter.push(event.target.value)

                // console.log(socialise_filter);

                // setSocialiseFilter(socialise_filter);
            }
            else if(event.target.id === 'localhost')
            {
                // filterLocal.push([...localhub_filter, event.target.value]);

                localhub_filter.push(event.target.value);

                
                
                
                this.props.dispatch({
                    type: SET_LOCAL_HUB_FILTER,
                    action: localhub_filter
                })

                console.log(localhub_filter);

                setLocalHubFilter(localhub_filter);
                // setLocalHubFilter([...localhub_filter, event.target.value]);
                // setLocalHubFilter(filterLocal);
                // this.props.localhub_filter.push(event);
            }
        };

        return (
            <div className="filterMainBox">
                <h4>Filter</h4>
                <div className="filterMainBodySection">
                    { this.props.page === "socialise" ? 
                    socializeFields.map((section, parentIndex) => 
                    <div key={parentIndex} className="socializeField">
                        { section?.label ? <h5>{ section.label }</h5> : null }
                        <div className="fieldsSection">
                            { section.fields.map((field, index) => 
                                <div key={index} className="filterCheckBox"> 
                                    <Checkbox 
                                        // id="socialise"
                                        // inputProps={{accessKey:"socialise"}}
                                        // value={field}
                                        // onChange={handleChange} 
                                        labelClassName="filterCheckBoxLabel"
                                        borderColor="#C4C4C4"
                                        borderWidth={1}
                                        borderRadius={5}
                                        size={14}
                                        label={field}
                                        icon={
                                            <div
                                                style={{
                                                backgroundColor: "#FF7C59",
                                                borderRadius: "3px",
                                                width: 10,
                                                height: 10
                                                }}
                                            >
                                            </div>
                                        }
                                        />     
                                </div>
                             )
                            }
                        </div>
                    </div>
                    )

                    : this.props.page === "localhub"? 
                        <div className="socializeField">
                            <div className="fieldsSection">
                                <FormGroup>
                                    {
                                        localhubFields.map((field, index) => 
                                            <div key={index} className="filterCheckBox"> 
                                                <Checkbox 
                                                // id="localhost"
                                                // inputProps={{accessKey:"localhub"}}
                                                // value={field}
                                                // onChange={handleChange} 
                                                labelClassName="filterCheckBoxLabel"
                                                borderColor="#C4C4C4"
                                                borderWidth={1}
                                                borderRadius={5}
                                                size={14}
                                                label={field}
                                                icon={
                                                    <div
                                                      className="filterCheckBoxIcon"
                                                      style={{
                                                        backgroundColor: "#FF7C59",
                                                        borderRadius: "3px",
                                                        width: 10,
                                                        height: 10
                                                      }}
                                                    >
                                                    </div>
                                                }
                                                /> 
                                            </div>
                                        )
                                    }
                                </FormGroup>
                            </div>
                        </div>
                    :
                    null }
                </div>
            </div>
        );
    }
}

PageFilters.propTypes = {
    // socialise_filter: PropTypes.array.isRequired,
    // localhub_filter: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        socialise_filter: state.filter.socialise_filter,
        localhub_filter: state.filter.localhub_filter
    }
}
export default connect(mapStateToProps, {setSocialiseFilter, setLocalHubFilter})(PageFilters);