import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
    const location = useLocation();
    return (
        <nav className="menuNavigationSection">
            <ul>
                <li>
                    <div className={location.pathname === '/' ? 'active_dot_def soc_dot_design' : ''}></div>
                    <Link
                        to="/"
                        className={location.pathname === '/' ? 'socialize_btn active_btn' : 'socialize_btn'}
                    >
                        Socialise
                    </Link>
                </li>
                <li>
                    <div className={location.pathname === '/shoutouts' ? 'active_dot_def shou_dot_design' : ''}></div>
                    <Link
                        to="/shoutouts"
                        className={location.pathname === '/shoutouts' ? 'shoutouts_btn active_btn' : 'shoutouts_btn'}
                    >
                        Shout
                        <span>
                            Outs
                        </span>
                    </Link>
                </li>
                <li>
                    <div className={location.pathname === '/localhub' ? 'active_dot_def local_dot_design' : ''}></div>
                    <Link
                        to="/localhub"
                        className={location.pathname === '/localhub' ? 'localhub_btn active_btn' : 'localhub_btn'}
                    >
                        Local
                        <span>Hub</span>
                    </Link>
                </li>
                <li>
                    <div className={location.pathname === '/outfits' ? 'active_dot_def out_dot_design' : ''}></div>
                    <Link
                        to="/outfits"
                        className={location.pathname === '/outfits' ? 'outfits_btn active_btn' : 'outfits_btn'}
                    >
                        Outfits
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navigation;