import {
  SET_USERS_LIST,
  SET_ACTIVE_USER_DATA
} from "../types";

const initialState = {
  loading: false,
  users: [],
  posts: [],
  userData: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USERS_LIST:
      return {
        ...state,
        users: action.payload
      };

    case SET_ACTIVE_USER_DATA: 
      return {
        ...state,
        userData: action.payload
      }

    default:
      return state;
  }
}
