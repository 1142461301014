import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setPageData, setLocalHubData, setPageStats} from "../redux/actions/pageAction";
import SpringModal from "../components/layout/sections/Modal";
import Posts from "../components/posts/posts";

class LocalHub extends Component {
    componentDidMount() {
        this.props.setPageStats(true);
        this.props.setPageData('localhub');
        this.props.setLocalHubData('localhub');
    }
    render() {
        return (
          <div className="shotouts_container">
                <SpringModal />
                { this.props.posts.map((post) => (
                    <Posts key={post.id} post={post}/>
                )) }
            </div>
        );
    }
}

LocalHub.propTypes = {
    data: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        data: state.page.data,
        posts: state.page.localHub

    }
}
export default connect(mapStateToProps, { setPageStats, setPageData, setLocalHubData })(LocalHub);