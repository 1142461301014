import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPageData, setPageStats } from "../redux/actions/pageAction";
import { getUsersData } from "../redux/actions/userActions";
import { Link } from 'react-router-dom';

class Socialise extends Component {
    componentDidMount() {
        this.props.setPageStats(true);
        this.props.setPageData('socialise');
        this.props.getUsersData();
    }

    render() {
        const peopleLis = this.props.people.map((person, index) =>
            <Link key={index} to={`user-profile/${person.id}`} >
                <div key={ person.id } className="userBoxStats" style={{ backgroundImage:`url(${person.image})` }}>
                    <div className="userBoxDetailStat">
                        <div>
                            { person.is_active ? <img src="active_dot.png" className="active_dot_class" alt="active dot" /> : '' }
                            <img src={ person.user_profile } alt="user status" className="icon_profile"/>
                                <label>{ person.name }</label>
                            { person.is_premium ? <img src="medal.png" alt="medal" className="medal_class" />: '' }
                        </div>
                        <div className="personDescriptionBox">
                            <div className="personDescriptionBox_des">
                                { person.description }
                            </div>
                            <div>
                                { person.is_friend ? <img src="is_friend_icon.png" alt="icon" /> : <img src="add_user_icon.png" className="addUserIcon" alt="icon" /> }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );

        return (
            <div>
                {peopleLis}
            </div>
        );
    }
}
Socialise.propTypes = {
    data: PropTypes.string.isRequired,
    people: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        data: state.page.data,
        people: state.users.users
    }
}

const mapActionsToProps = { setPageData, getUsersData, setPageStats };
  

export default connect(mapStateToProps, mapActionsToProps )(Socialise);