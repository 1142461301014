import {
  SET_USERS_LIST,
  SET_ACTIVE_USER_DATA,
  PAGE_LOADING
} from "../types";
import axios from "axios";
export const getUsersData = (filterData, history) => (dispatch) => {

  // Testing ground TODO remove later
  const profileStatus = {
    single: 1,
    group: 3,
    couple: 2
  }

  var randomProperty = function (obj) {
    var keys = Object.keys(obj);
    var randomInt = obj[keys[ keys.length * Math.random() << 0]];
    if(randomInt === 1){
      return 'single_icon.png';
    } else if(randomInt === 2) {
      return 'couple_icon.png';
    } else {
      return 'group_icon.png'
    }
  };

  var testImages = [
    'user1.png',
    'user2.png',
    'user3.png',
    'user4.png'
  ];

  const userNames = [
    'Elena', 'Dany', 'Jordan', 'Elena, Jordan, Zack...','Bella'
  ];

  const userDescription = [
    'Go to biggest party',
    'What want description must be in this line'
  ];

  function randomBool()
  {
    if(random_item([0,1]) === 1){
      return true;
    }else {
      return false;
    }
  }

  function random_item(items) { return items[Math.floor(Math.random()*items.length)]; }

  let people = [];
  const geolocation = navigator.geolocation;
  if (!geolocation) {
    return new Error('Not Supported');
  }
  geolocation.getCurrentPosition((position) => {
    for (let i = 1; i < 21; i++) {
      people.push({
        id: i,
        name: random_item(userNames),
        description: random_item(userDescription),
        is_premium: randomBool(),
        is_active: randomBool(),
        is_friend: randomBool(),
        image: random_item(testImages),
        user_profile: randomProperty(profileStatus),
      });
    }
    dispatch({
      type: SET_USERS_LIST,
      payload: people
    });
    dispatch({
      type: PAGE_LOADING,
      payload: false
    });
  }, (err) => {
    if(err.code === 1) {
      axios.get('http://ip-api.com/json?fields=3207167').then((e) => {
        for (let i = 1; i < 21; i++) {
          people.push({
            id: i,
            name: random_item(userNames),
            description: random_item(userDescription),
            is_premium: randomBool(),
            is_active: randomBool(),
            is_friend: randomBool(),
            image: random_item(testImages),
            user_profile: randomProperty(profileStatus),
          });
        }
        dispatch({
          type: SET_USERS_LIST,
          payload: people
        });
          dispatch({
            type: PAGE_LOADING,
            payload: false
          });
      });
    }
  });
}

export const getUserData = (userId, history) => (dispatch) => {
  let userDataObj = {};

  var testImages = [
    'user1.jpg',
    'user2.jpg',
    'user3.jpg',
    'user4.jpg'
  ];

  function random_item(items) { return items[Math.floor(Math.random()*items.length)]; }
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }



  axios.get('http://88c3-d2ba.dhi.codes/admin/user/me', {
    headers: {
      'accept': 'application/json',
      'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZWU0OTg2N2I1NzQ4MjU1OGEwODg4OCIsInVzZXJuYW1lIjoiRGFueTQ1IiwiaWF0IjoxNjc2NTYwODA5LCJleHAiOjE2NzcxNjA4MDl9.zPbamlxPFKiQVAHXBgv2KnUNQPStT8VnXQtQvTlHmbs"
    }
  })
  .then((e) => {

    userDataObj = e.data.data;
    userDataObj.userProfile.age = getAge(userDataObj.userProfile.age);

    userDataObj.linkedAccounts = 
    [
      {
        id: 1,
        name: "Elena",
        onlineStatus: true,
        image: random_item(testImages) 
      },
      {
        id: 2,
        name: "Nataly",
        onlineStatus: false,
        image: random_item(testImages)
      },
      {
        id: 3,
        name: "Michael",
        onlineStatus: true,
        image: random_item(testImages)
      }
    ];

    userDataObj.userReviews = 
    [
      {
        id: 1,
        onlineStatus: true,
        image: "user1.jpg",
        description: "Reviews ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      },
      {
        id: 2,
        onlineStatus: false,
        image: "user2.jpg",
        description: "Reviews ipsum dolor sit amet, consectetur"
      },
      {
        id: 3,
        onlineStatus: true,
        image: "user3.jpg",
        description: "Reviews ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt."
      },
      {
        id: 4,
        onlineStatus: false,
        image: "user4.jpg",
        description: "Reviews ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      },
    ]
    

    userDataObj.userDetails = 
    {
      points: Math.floor(Math.random() * 50),
      distance: Math.floor(Math.random() * 100),
      reviews: Math.floor(Math.random() * 40)
    }

    dispatch({
      type: SET_ACTIVE_USER_DATA,
      payload: userDataObj
    })

    dispatch({
      type: PAGE_LOADING,
      payload: false
    })
  })






  
}