import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";


class SearchBar extends Component {
    render(){
        const allowed_pages = ['socialise','localhub'];
        return(
            <div>
                {
                    allowed_pages.includes(this.props.data) ?
                        <div className="searchInputBlock">
                            <input type="text" className="searchBarInput" aria-label="Search" placeholder='Search'/>
                            <input type="image" alt="search icon in field" src="search.svg" className="searchIconImage"/>
                        </div>
                    : null
                }
            </div>
        )
    }
}

SearchBar.propTypes = {
    data: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        data: state.page.data
    }
}
export default connect(mapStateToProps)(SearchBar);