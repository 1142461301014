import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class PageDetails extends Component {
    render(){
        let textOutput = '';
        switch (this.props.data) {
            case 'shoutouts':
                textOutput = "See what’s happening around you in real-time! Shout Outs is your space to share, create and discover.";
                break;
            case 'localhub':
                textOutput = "Ever felt like doing something but didn't know what? " +
                    " Local Hub connects you and local businesses in your community. " +
                    "Find last minute offers, events, and sales on anything from food and drink to clothes. ";
                break;
            case 'socialise':
                textOutput = "Socialise connects you with fun people near you to organise something spontaneous.\n" +
                    "\n" +
                    "Go online, select if you are solo/couple/group or a family and write a sentence to let other users know what you are up for. Plans change? Got you covered. Just go off and then back online to reset your status.";
                break;
            case 'outfits':
                textOutput = "Nervous about meeting a new friend? Going to an event and not sure what fits the dress code? Wish you had crowd sourced fashion advice only when you want it? YOU GOT IT!\n" +
                    "\n" +
                    "Post your outfit choices and get feedback through the thumbs up/thumbs down and comments section and, don’t forget the Community guidelines.";
                break;
            case 'user-profile':
                textOutput = "Link your partner or other user profiles to yours, it's as simple as that."+
                "\n" + 
                "PLUS read and/or leave user reviews to validate users profiles, enforcing safety and that Laterz is not a “hook-up” app.";
                break;
            
            default:
                textOutput = "";
        }

        return (
            <div className="pageDetailsSection">
                <h3>
                    { 
                    this.props.data === 'user-profile' ? 
                        'My Profile' : 
                    this.props.data === 'localhub' ?
                        'What is Local Hub ?' : 
                    this.props.data === 'shoutouts' ?
                        'What is Shout Outs ?' :  
                        "What is " + this.props.data + " ?" 
                    }
                    
                </h3>
                <p >
                    { textOutput }
                </p>
            </div>
        )
    }
}

PageDetails.propTypes = {
    data: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        data: state.page.data
    }
}
export default connect(mapStateToProps)(PageDetails);