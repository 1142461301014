import React, {Component} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
// import axios from "axios";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

// Theme components
import {ThemeProvider} from "@mui/styles";
import theme from "./util/theme";
import MainLayout from './components/layout/MainLayout'

// pages
import ShoutOuts from "./pages/shoutOuts";
import Socialise from "./pages/socialise";
import Outfits from "./pages/outfits";
import LocalHub from "./pages/localHub";
import axios from "axios";
import { BASE_URL } from "./util/config";
import UserProfile from "./pages/userProfile";


// components
axios.defaults.baseURL = BASE_URL;

class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router>

                        <MainLayout>
                            <Routes>
                                <Route exact path="/" element={<Socialise/>}/>
                                <Route exact path="/shoutouts" element={<ShoutOuts/>}/>
                                <Route exact path="/localhub" element={<LocalHub/>}/>
                                <Route exact path="/outfits" element={<Outfits/>}/>
                                <Route exact path="/user-profile/:id" element={<UserProfile/>} />
                            </Routes>
                        </MainLayout>

                    </Router>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default App;
