import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, SwitchProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {setFilterToActiveUsers} from "../../../redux/actions/filterSearchAction";

class ChangeStatus extends Component<SwitchProps> {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        this.props.setFilterToActiveUsers(event.target.checked);
    }
    render(){
        const IOSSwitch = styled((props: SwitchProps) => (
            <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} checked={ this.props.filter } onChange={ this.handleChange } />
        ))(({ theme }) => ({
            checked: this.props.filter,
            width: 42,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[600],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 22,
                height: 22,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        }));

        return(
            <div className="statusBlock">
                <div className="availableLabel">
                    <label>Available now</label>
                </div>
                <div className="availableButton">
                    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />
                </div>
            </div>
        )
    }
}

ChangeStatus.propTypes = {
    filter: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {

    return {
        filter: state.filter.active_users
    }
}
export default connect(mapStateToProps, { setFilterToActiveUsers })(ChangeStatus);