import {
    PAGE_STATE,
    PAGE_LOADING,
    SET_SHOUT_OUTS_POSTS,
    SET_LOCAL_HUB_POSTS,
    OPEN_MODAL,
    SET_OUTFIT_POSTS
} from "../types";

// login user action creator
export const setPageStats = (page_status, history) => (dispatch) => {
    dispatch({ type: PAGE_LOADING, payload: page_status});
};

export const setPageData = (pageData) => (dispatch) => {
    dispatch({ type: PAGE_STATE, payload: pageData })
}

export const setShoutOutsData = (payload) => (dispatch) => {
    // Testing data TODO Replace this code
    var testImages = [
        'user1.png',
        'user2.png',
        'user3.png',
        'user4.png'
    ];

    var postImages = [
        'posts/post1.png',
        'posts/post2.png',
        'posts/post3.png',
        'posts/post4.png',
        'posts/post5.png'
    ];

    const userNames = [
        'Elena, Jordan, Zack...',
        'Elena Forest',
        'Jordan Blackberry'
    ];

    const postDescription = [
        'Paradise Beach description here dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt. Dolor sit amet, consectetur adipiscing elit.',
        'Dolor sit amet, consectetur adipiscing. Consectetur adipiscing elit, eiusmod tempor incididunt',
        ''
    ];

    const titleNames = [
        'Who wants to go “Paradise Beach” tomorrow?',
        'Anyone seen my lost cat?',
        'Who wants to go Night Club tomorrow night?',
        'Dolor sit amet, consectetur adipiscing.'
    ];

    function random_item(items) { return items[Math.floor(Math.random()*items.length)]; }
    function randomBool()
    {
        if(random_item([0,1]) === 1) {
            return true;
        } else {
            return false;
        }
    }

    // Testing data
    let posts = [];
    for (let i = 0; i < 10; i++) {
        posts.push({
            id: i,
            title: random_item(titleNames),
            description: random_item(postDescription),
            media: random_item(postImages),
            audioFile: "simple.mp3",
            // audioFile: "",
            user:{
                id: i+1,
                name: random_item(userNames),
                is_premium: randomBool(),
                image: random_item(testImages),
            },
            is_new: randomBool(),
            actions: { like_dislike: false },
        });
    }

    dispatch({
        type: SET_SHOUT_OUTS_POSTS,
        payload: posts
    });

    dispatch({
        type: PAGE_LOADING,
        payload: false
    });
}

export const setLocalHubData = (payload) => (dispatch) => {
     // Testing data TODO Replace this code
     var testImages = [
        'user1.png',
        'user2.png',
        'user3.png',
        'user4.png'
    ];
    
    var postImages = [
        'posts/localhub1.jpg',
        'posts/localhub2.jpg',
        'posts/localhub3.jpg',
        'posts/localhub4.jpg',
        'posts/localhub5.jpg',
        'posts/localhub6.jpg'
    ];

    let postVideos = [
        'posts/videos/testVideo.mp4'
    ];

    const userNames = [
        'Elena, Jordan, Zack...',
        'Elena Forest',
        'Jordan Blackberry'
    ];

    const postDescription = [
        'Paradise Beach description here dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt. Dolor sit amet, consectetur adipiscing elit.',
        'Dolor sit amet, consectetur adipiscing. Consectetur adipiscing elit, eiusmod tempor incididunt',
        ''
    ];

    const titleNames = [
        'Who wants to go “Paradise Beach” tomorrow?',
        'Anyone seen my lost cat?',
        'Who wants to go Night Club tomorrow night?',
        'Dolor sit amet, consectetur adipiscing.'
    ];

    function random_item(items) { return items[Math.floor(Math.random()*items.length)]; }
    function randomBool()
    {
        if(random_item([0,1]) === 1) {
            return true;
        } else {
            return false;
        }
    }

    function randomMedia()
    {
        const randomImage = random_item(postImages);
        const randomVideo = random_item(postVideos);
        const randomGallery = postImages;

         return random_item([randomImage, randomVideo, randomGallery]);
    }

    // Testing data

    let posts = [];
    for (let i = 0; i < 10; i++) { 
        posts.push({
            id: i,
            title: random_item(titleNames),
            description: random_item(postDescription),
            media: randomMedia(),
            // audioFile: "simple.mp3",
            audioFile: null,
            user:{
                id: i+1,
                name: random_item(userNames),
                is_premium: randomBool(),
                image: random_item(testImages),
            },
            is_new: randomBool(),
            actions: { like_dislike: false },
        });
    }

    dispatch({
        type: SET_LOCAL_HUB_POSTS,
        payload: posts
    });

    dispatch({
        type: PAGE_LOADING,
        payload: false
    });
}

export const setOutfitsData = (payload) => (dispatch) => {
      // Testing data TODO Replace this code
      var testImages = [
        'user1.png',
        'user2.png',
        'user3.png',
        'user4.png'
    ];
    
    var postImages = [
        'posts/localhub1.jpg',
        'posts/localhub2.jpg',
        'posts/localhub3.jpg',
        'posts/localhub4.jpg',
        'posts/localhub5.jpg',
        'posts/localhub6.jpg'
    ];

    const userNames = [
        'Elena, Jordan, Zack...',
        'Elena Forest',
        'Jordan Blackberry'
    ];

    const postDescription = [
        'Paradise Beach description here dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt. Dolor sit amet, consectetur adipiscing elit.',
        'Dolor sit amet, consectetur adipiscing. Consectetur adipiscing elit, eiusmod tempor incididunt',
        ''
    ];

    function random_item(items) { return items[Math.floor(Math.random()*items.length)]; }
    function randomBool()
    {
        if(random_item([0,1]) === 1) {
            return true;
        } else {
            return false;
        }
    }

    function randomMedia()
    {
        const randomImage = random_item(postImages);
        const randomGallery = postImages;

         return random_item([randomImage, randomGallery]);
    }

    // Testing data

    let posts = [];
    for (let i = 0; i < 10; i++) { 
        posts.push({
            id: i,
            // title: random_item(titleNames),
            title: null,
            description: random_item(postDescription),
            media: randomMedia(),
            // audioFile: "simple.mp3",
            audioFile: null,
            user:{
                id: i+1,
                name: random_item(userNames),
                is_premium: randomBool(),
                image: random_item(testImages),
            },
            is_new: randomBool(),
            actions: { like_dislike: true },
        });
    }

    dispatch({
        type: SET_OUTFIT_POSTS,
        payload: posts
    });
    dispatch({
        type: PAGE_LOADING,
        payload: false
    });
}

export const openModal = (payload) => (dispatch) => {
    dispatch({ type: OPEN_MODAL });
}