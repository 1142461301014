import {
    SET_USER_STATUS_FILTER,
    SET_SOCIALISE_FILTER,
    SET_LOCAL_HUB_FILTER,
    CLEAR_SOCIALISE_FILTER,
    CLEAR_LOCAL_HUB_FILTER
} from "../types";

const filterInitialState = {
    active_users: false,
    socialise_filter: [],
    localhub_filter: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = filterInitialState, action) {
    switch (action.type) {
        case SET_USER_STATUS_FILTER:
            return {
                ...state,
                active_users: action.payload
            };
        
        case SET_SOCIALISE_FILTER: 
            return {
                ...state,
                socialise_filter: action.payload
            }

        case SET_LOCAL_HUB_FILTER: 
            return {
                ...state,
                localhub_filter: action.payload
            }
        
        case CLEAR_SOCIALISE_FILTER: 
            return {
                ...state,
                socialise_filter: []
            }

        case CLEAR_LOCAL_HUB_FILTER: 
            return {
                ...state,
                localhub_filter: []
            }

        default:
            return state;
    }
}
