import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import { connect } from "react-redux";
import React, { Component } from "react";
// web.cjs is required for IE11 support
import { useSpring, animated} from '@react-spring/web';
import { openModal } from '../../../redux/actions/pageAction';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

class SpringModal extends Component {
    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 6
        };


        return (
        <div>
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={this.props.status}
            onClose={this.props.openModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={ this.props.status }>
            <Box sx={style}>
              <div className='modalBoxClass'>
                <input type="image" src = {'/close.svg'} alt="close modal image" className='closeModalImage' onClick={ this.props.openModal } />
                <input type="image" src = {'/mask_group.png'} alt="close modal image" />

                <div className='modalLogoSection'>
                  <img src='/group_139.svg' alt='Modal laterz logo' />
                </div>
                <div className='modalTitleBox'>
                  <center>
                    <p>Lorem ipsum dolor sit <br></br> amet, consectetur adipiscing</p>
                  </center>
                </div>
                <div className='modalBodyDescriptionBox'>
                    <p>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                </div>
                <div className='modalLinkSectionBox'>
                  <div>
                    <input type="image" src = {'/app_store_img.png'} alt="app store image link" />
                    <input type="image" src = {'/google_playstore.png'} alt="play store image link" />
                  </div>
                </div>
              </div>
            </Box>
            </Fade>
        </Modal>
        </div>
    );
    }
}

SpringModal.propTypes = {
    status: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
    return {
        status: state.page.modalStatus
    }
}
export default connect(mapStateToProps, { openModal })(SpringModal);