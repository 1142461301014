import { Component } from "react";
import { Container, Grid, Avatar } from "@mui/material";
import Navigation from "./Navigation";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import PageTitle from "./sections/PageTitle";
import SearchBar from "./sections/SearchBar";
import PageDetails from "./sections/PageDetails";
import ChangeStatus from "./sections/ChangeStatus";
import RightLayoutSection from "./sections/RightLayoutSection";


class MainLayout extends Component {
    componentDidMount() {}
    styles = {
        avatar: {
            margin: 10,
        }
    }
    render = () => {
        return (
        <div className={ this.props.modalStatus ? 'blureBody' : ''} >
            <Container>
                <div className="header navbar">
                    <Grid container justify="center" display={"inline-flex"}>
                        <Grid item md={3.5}>
                            <div className="logoContainer">
                                <img alt="Laterz logo" src="/main_logo.png"/>
                            </div>
                        </Grid>
                        <Grid item md={6.5}>
                            <div className="middleSection">
                                <div className="navBarTitleContainer">
                                    <PageTitle />
                                </div>
                                <div className="searchBar">
                                    <SearchBar />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2}>
                            <div className="userProfileImage">
                                <div className="imageSection">
                                    <Avatar alt="Remy Sharp" />
                                </div>
                                <div className="userNameSection">
                                    Milivoje Ivic
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Container className="container_space">
                <Grid container justify="center" display={"inline-flex"}>
                    <Grid item md={3.5}>
                        <div className="navigationContainer">
                            <Navigation/>
                        </div>
                        <div className="aboutPageContainer">
                            <PageDetails />
                        </div>
                        <div className="setStatusSearch">
                            <ChangeStatus />
                        </div>
                    </Grid>
                    <Grid item md={6.5} className="mainContainer_feeds">
                        <div className="pageSection">{ this.props.children }</div>
                        { this.props.contentLoading ? 
                        <div className="loadingImageSection">
                                <input type="image" alt="loading image" src="/Spin-1s-200px.svg" className="loadingImageClass" />
                        </div>
                        : null }
                    </Grid>
                    <Grid item md={2} className="right_section_filter">
                        <RightLayoutSection />
                    </Grid>
                </Grid>
            </Container>
        </div>
        );
    };
}

MainLayout.propTypes = {
    data: PropTypes.string.isRequired,
    modalStatus: PropTypes.bool.isRequired,
    contentLoading: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
    return {
        data: state.page.data,
        modalStatus: state.page.modalStatus,
        contentLoading: state.page.pageLoading
    }
}
export default connect(mapStateToProps)(MainLayout);
