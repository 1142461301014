import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import pageReducer from "./reducers/pageReducer";
import filterSearchReducer from "./reducers/filterSearchReducer";
import userReducer from "./reducers/userReducer";

const initialState = {};


const middleware = [thunk];

const reducers = combineReducers({
  page: pageReducer,
  filter: filterSearchReducer,
  users: userReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
