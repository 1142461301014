import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setPageData, setOutfitsData, setPageStats} from "../redux/actions/pageAction";
import SpringModal from "../components/layout/sections/Modal";
import Posts from "../components/posts/posts";

class Outfits extends Component {
    componentDidMount() {
        this.props.setPageStats(true);
        this.props.setPageData('outfits');
        this.props.setOutfitsData('outfits');
    }
    render() {
        return (
            <div className="shotouts_container">
            <SpringModal />
            { this.props.posts.map((post) => (
                <Posts key={post.id} post={post}/>
            )) }
        </div>
        );
    }
}
Outfits.propTypes = {
    data: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        data: state.page.data,
        posts: state.page.outfits
    }
}
export default connect(mapStateToProps, { setPageData, setOutfitsData, setPageStats })(Outfits);