import {
  PAGE_STATE,
  PAGE_LOADING, 
  SET_SHOUT_OUTS_POSTS,
  SET_LOCAL_HUB_POSTS,
  OPEN_MODAL,
  SET_OUTFIT_POSTS
} from "../types";

const initialState = {
  loading: false,
  data: 'shoutouts',
  modalStatus: false,
  pageLoading: true,
  shoutOuts: [],
  localHub: [],
  outfits: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload
      };

    case PAGE_STATE:
      return {
        ...state,
        data: action.payload
      };

    case SET_SHOUT_OUTS_POSTS:
      return {
        ...state,
        shoutOuts: action.payload
      }

    case SET_LOCAL_HUB_POSTS: 
      return {
        ...state,
        localHub: action.payload
      }
    case SET_OUTFIT_POSTS: 
      return {
        ...state,
        outfits: action.payload
      }

    case OPEN_MODAL:
      let status = false;
      if(!state.modalStatus){
        status = true;
      } else {
        status = false;
      }

      return {
        ...state,
        modalStatus: status
      }

    default:
      return state;
  }
}
